import { FC } from 'react';

export const DaysCustomizedAxisTick: FC = ({ x, y, payload }: any) => {
  const label = JSON.parse(payload.value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill='#666'>
        <tspan textAnchor='middle' x='0'>
          {label.top}
        </tspan>
        <tspan textAnchor='middle' x='0' dy='20'>
          {label.bottom}
        </tspan>
      </text>
    </g>
  );
};
