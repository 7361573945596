import { useCallback } from 'react';
import { signIn } from '../services/gapi';
import { useFetchReducer } from './use_fetch_reducer';

export function useGapiLogin() {
  const [state, dispatch] = useFetchReducer<void>();
  const login = useCallback(async () => {
    if (state.loading) {
      return;
    }
    try {
      dispatch({ type: 'loadStarted' });
      await signIn();
      dispatch({ type: 'loadSucceeded', data: undefined });
    } catch (err: unknown) {
      const error = err instanceof Error ? err : new Error('unknown error');
      dispatch({ type: 'loadFailed', error });
    }
  }, [dispatch, state.loading]);
  return [login, state] as const;
}
