import { FC } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DayDistributionInfo } from '../../data_helpers';
import { DaysCustomizedAxisTick } from '../DaysCustomAxisTick/DaysCustomAxisTick';

interface Props {
  data: DayDistributionInfo[];
}
export const DaysStackedBarChart: FC<Props> = ({ data }) => {
  return (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis
        dataKey='label'
        height={60}
        interval={0}
        tick={<DaysCustomizedAxisTick />}
      />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='work' stackId='a' fill='#3150b6' />
      <Bar dataKey='family' stackId='a' fill='#c5ca82' />
      <Bar dataKey='misc' stackId='a' fill='#165307' />
    </BarChart>
  );
};
