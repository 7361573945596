import { FC } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { DaySingleValue } from '../../data_helpers';
import { DaysCustomizedAxisTick } from '../DaysCustomAxisTick/DaysCustomAxisTick';

interface Props {
  data: DaySingleValue[];
}
export const DaysSingleBarChart: FC<Props> = ({ data }) => {
  return (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis
        dataKey='label'
        height={60}
        interval={0}
        tick={<DaysCustomizedAxisTick />}
      />
      <YAxis />
      <Tooltip />
      <Bar dataKey='value' stackId='a' fill='#3150b6' />
    </BarChart>
  );
};
