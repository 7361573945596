export function getBeginOfDate(date: Date): Date {
  const res = new Date(date);
  res.setHours(0);
  res.setMinutes(0);
  res.setSeconds(0);
  return res;
}

export function getOffsetDate(date: Date, offsetDays: number): Date {
  const res = new Date(date);
  res.setDate(date.getDate() - offsetDays);
  return res;
}

const dayList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export function getShortenedDayText(date: Date): string {
  return dayList[date.getDay()];
}

export function getTimeInHour(date: Date) {
  return date.getHours() + date.getMinutes() / 60;
}
