import { useCallback } from 'react';
import {
  CalendarEvent,
  listEvents,
  loadCalendarClient,
} from '../services/gapi';
import { useFetchReducer } from './use_fetch_reducer';

export function useCalendarEvents() {
  const [state, dispatch] = useFetchReducer<CalendarEvent[]>();

  const loadEvents = useCallback(
    async (min: Date, max: Date) => {
      if (state.loading) {
        return;
      }
      try {
        dispatch({ type: 'loadStarted' });
        const client = await loadCalendarClient();
        const data = await listEvents(client, min, max);
        dispatch({ type: 'loadSucceeded', data });
      } catch (err: unknown) {
        const error = err instanceof Error ? err : new Error('unknown error');
        dispatch({ type: 'loadFailed', error });
      }
    },
    [dispatch, state.loading],
  );
  return [loadEvents, state] as const;
}
