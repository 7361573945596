import React from 'react';
import './App.css';
import { useCalendarEvents } from '../../hooks/use_calendar_events';
import { useGapiLogin } from '../../hooks/use_gapi_login';
import { getBeginOfDate, getOffsetDate } from '../../date_helpers';
import { DaysStackedBarChart } from '../DaysBarChart/DaysBarChart';
import {
  makeDaysEndWorkingHourData,
  makeDaysFamilyTimeDurationData,
  makeDaysStartWorkingHourData,
  makeDaysTimeDistributionData,
  makeDaysTimeDurationData,
  makeDaysWorkTimeDurationData,
} from '../../data_helpers';
import { ChartContainer } from '../ChartContainer/ChartContainer';
import { DaysLineChart } from '../DaysLineChart/DaysLineChart';
import { DaysSingleBarChart } from '../DaysSingleBarChart/DaysSingleBarChart';

function App() {
  const [loadEvents, eventsState] = useCalendarEvents();
  const [signIn, signInState] = useGapiLogin();

  const error = eventsState.error ?? signInState.error;
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div className='App'>
      <div>
        <button onClick={signIn}>Authorize</button>
        <button
          onClick={() => {
            const today = getBeginOfDate(new Date());
            const sevenDaysAgo = getOffsetDate(today, 7);
            loadEvents(sevenDaysAgo, today);
          }}>
          Days
        </button>
        <button
          onClick={() => {
            const today = getBeginOfDate(new Date());
            const thirtyDaysAgo = getOffsetDate(today, 30);
            loadEvents(thirtyDaysAgo, today);
          }}>
          Weeks
        </button>
      </div>

      <ChartContainer
        title='Time distribution'
        eventsState={eventsState}
        parser={makeDaysTimeDistributionData}
        renderChart={(data) => (
          <DaysStackedBarChart data={data}></DaysStackedBarChart>
        )}></ChartContainer>
      <ChartContainer
        title='Time duration'
        eventsState={eventsState}
        parser={makeDaysTimeDurationData}
        renderChart={(data) => (
          <DaysStackedBarChart data={data}></DaysStackedBarChart>
        )}></ChartContainer>
      <ChartContainer
        title='Workday start work time'
        eventsState={eventsState}
        parser={makeDaysStartWorkingHourData}
        renderChart={(data) => (
          <DaysLineChart data={data}></DaysLineChart>
        )}></ChartContainer>
      <ChartContainer
        title='Workday end work time'
        eventsState={eventsState}
        parser={makeDaysEndWorkingHourData}
        renderChart={(data) => (
          <DaysLineChart data={data}></DaysLineChart>
        )}></ChartContainer>
      <ChartContainer
        title='Work time duration'
        eventsState={eventsState}
        parser={makeDaysWorkTimeDurationData}
        renderChart={(data) => (
          <DaysSingleBarChart data={data}></DaysSingleBarChart>
        )}></ChartContainer>
      <ChartContainer
        title='Family time duration'
        eventsState={eventsState}
        parser={makeDaysFamilyTimeDurationData}
        renderChart={(data) => (
          <DaysSingleBarChart data={data}></DaysSingleBarChart>
        )}></ChartContainer>
    </div>
  );
}

export default App;
