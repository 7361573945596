import * as React from 'react';
import { Parser } from '../../data_helpers';
import { FetchState } from '../../hooks/use_fetch_reducer';
import { CalendarEvent } from '../../services/gapi';

interface Props {
  eventsState: FetchState<CalendarEvent[]>;
  parser: Parser;
  title: string;
  renderChart: (data: any[]) => React.ReactElement;
}

export const ChartContainer: React.FC<Props> = ({
  eventsState: { loading, error, data },
  parser,
  title,
  renderChart,
}) => {
  const parsedData = React.useMemo(
    () => (data ? parser(data) : []),
    [data, parser],
  );
  if (loading) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (!data) {
    return <div>No results</div>;
  }
  return (
    <div>
      <div>{title}</div>
      {renderChart(parsedData)}
    </div>
  );
};
