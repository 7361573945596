import { CalendarName, calendarNameToId } from '../constants';

type LoadCallback = (...args: any[]) => void;
type LoadConfig = {
  callback: LoadCallback;
  onerror?: Function | undefined;
  timeout?: number | undefined;
  ontimeout?: Function | undefined;
};
type CallbackOrConfig = LoadConfig | LoadCallback;

interface Gapi {
  auth2?: GapiAuth2;
  client?: GapiClient;

  /**
   * Pragmatically initialize gapi class member.
   * Reference: https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiloadlibraries-callbackorconfig
   */
  load(apiName: string, callback: CallbackOrConfig): void;
}

interface GapiAuth2 {
  init(args: {
    /**
     * The app's client ID, found and created in the Google Developers Console.
     */
    client_id?: string;
  }): Promise<void>;
  getAuthInstance(): any;
}

interface GapiClient {
  calendar?: GapiCalendarClient;

  setApiKey(apiKey: string): void;
  load(url: string): Promise<void>;
}

interface GapiCalendarClient {
  events: {
    list: any;
  };
}

declare global {
  interface Window {
    gapi: Gapi;
  }
}

let initPromise: Promise<void> | undefined;
let loadCalendarPromise: Promise<GapiCalendarClient> | undefined;

export async function lazyInit() {
  await initApi();
  await loadCalendarClient();
}

export function initApi() {
  if (initPromise) {
    return initPromise;
  }
  initPromise = new Promise<void>((resolve, reject) => {
    window.gapi.load('client:auth2', async () => {
      console.log('gapi loading');
      try {
        await window.gapi.auth2?.init({
          client_id:
            '1048540191440-rnjt08p41lf470umnkdiim2mkntdt3i1.apps.googleusercontent.com',
        });
        resolve();
      } catch (e: unknown) {
        if (e instanceof Error) {
          reject(e);
        }
        if ((e as any).details) {
          reject(new Error((e as any).details));
        }
        reject(new Error('unknown error'));
      }
    });
  });
  return initPromise;
}

export async function signIn() {
  await initApi();

  return window.gapi.auth2
    ?.getAuthInstance()
    .signIn({
      scope:
        'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly',
    })
    .then(
      () => {
        console.log('Sign-in successful');
      },
      (err: unknown) => {
        console.error('Error signing in', err);
      },
    );
}

export function loadCalendarClient() {
  if (loadCalendarPromise) {
    return loadCalendarPromise;
  }
  loadCalendarPromise = new Promise<GapiCalendarClient>(
    async (resolve, reject) => {
      try {
        await initApi();

        window.gapi.client?.setApiKey(
          'AIzaSyBEMio9h6miamV5y6bkqwSicnrjUuIQC_w',
        );
        await window.gapi.client?.load(
          'https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest',
        );
        console.log('GAPI client loaded for API');
        if (!window.gapi.client?.calendar) {
          throw new Error('Calendar client instance is missing.');
        }
        resolve(window.gapi.client.calendar);
      } catch (err: unknown) {
        reject(err);
      }
    },
  );
  return loadCalendarPromise;
}

export interface CalendarEvent {
  id: string;
  summary: string;
  startTime: Date;
  endTime: Date;
  type: string;
}

let counter = 0;
function convertEvent(type: string, rawEvent: any) {
  // TODO: split the event if it crosses the day boundary.
  return {
    // adding a counter suffix because we saw ids can be identical in different
    // events.
    id: rawEvent.id + counter++,
    summary: rawEvent.summary,
    startTime: new Date(rawEvent.start.dateTime),
    endTime: new Date(rawEvent.end.dateTime),
    type,
  };
}

export async function listEvents(
  calendarClient: GapiCalendarClient,
  min: Date,
  max: Date,
) {
  const calendarNames = Object.keys(calendarNameToId);
  const results: CalendarEvent[] = [];
  await Promise.all(
    calendarNames.map((calendarName) =>
      listCalendarEvents(
        calendarClient,
        calendarName as CalendarName,
        min,
        max,
      ).then((events) => {
        results.push(...events);
      }),
    ),
  );
  return results;
}

async function listCalendarEvents(
  calendarClient: GapiCalendarClient,
  calendarName: CalendarName,
  min: Date,
  max: Date,
) {
  const results: any[] = [];
  let nextPageToken: string | undefined;
  const calendarId = calendarNameToId[calendarName];
  try {
    do {
      console.log('requesting calendar events for ', calendarId);
      const response = await calendarClient.events.list({
        calendarId,
        timeMax: max.toISOString(),
        timeMin: min.toISOString(),
        singleEvents: true,
        pageToken: nextPageToken,
      });
      results.push(
        ...response.result.items
          .filter((evt: any) => filterRawEvent(evt, min))
          .map((item: any) => convertEvent(calendarName, item)),
      );
      nextPageToken = response.result.nextPageToken;
    } while (nextPageToken);
    return results;
  } catch (err: unknown) {
    console.error('Execute error', err);
    throw err;
  }
}

function filterRawEvent(evt: any, min: Date) {
  return (
    evt.status !== 'cancelled' &&
    evt.start.dateTime &&
    new Date(evt.start.dateTime) > min &&
    evt.creator.email === 'zerolxy@gmail.com'
  );
}
