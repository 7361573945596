import { CalendarName } from './constants';
import {
  getBeginOfDate,
  getShortenedDayText,
  getTimeInHour,
} from './date_helpers';
import { CalendarEvent } from './services/gapi';

function toMMDD(date: Date) {
  return `${date.getMonth() + 1}/${date.getDate()}`;
}

type CommonDayInfo = {
  date: Date;
  label: string;
  events?: CalendarEvent[];
};

export type DayDistributionInfo = {
  [K in CalendarName]: number;
} & CommonDayInfo;

export type DaySingleValue = {
  value: number;
} & CommonDayInfo;

export type Parser = (events: CalendarEvent[]) => any[];

/**
 * map = {
 *   '1/2': {work: 10, family: 3, misc: 3, events: []},
 *   '1/3': {work: 10, family: 3, misc: 3, events: []},
 *   // ...
 * }
 */
function createDaysTimeDurationMap(events: CalendarEvent[]) {
  const map: Record<string, DayDistributionInfo> = {};
  for (const evt of events) {
    const mmdd = toMMDD(evt.startTime);
    let info = map[mmdd];
    if (!info) {
      info = {
        date: getBeginOfDate(evt.startTime),
        work: 0,
        family: 0,
        misc: 0,
        events: [],
        label: JSON.stringify({
          top: `${mmdd}`,
          bottom: getShortenedDayText(evt.startTime),
        }),
      };
      map[mmdd] = info;
    }
    const durationInHour =
      (evt.endTime.getTime() - evt.startTime.getTime()) / 1000 / 60 / 60;
    if (evt.type in info) {
      const duration = info[evt.type as keyof DayDistributionInfo] as number;
      info[evt.type as CalendarName] = durationInHour + duration;
    }
    info.events?.push(evt);
  }
  return map;
}

export const makeDaysTimeDistributionData: Parser = (events) => {
  const map = createDaysTimeDurationMap(events);
  return Object.values(map)
    .sort((a, b) => a.date.getTime() - b.date.getTime())
    .map((info) => {
      const total = info.work + info.family + info.misc;
      const distributionInfo = {
        ...info,
        work: info.work / total,
        family: info.family / total,
        misc: info.misc / total,
      };
      return distributionInfo;
    });
};

export const makeDaysTimeDurationData: Parser = (events) => {
  const map = createDaysTimeDurationMap(events);
  return Object.values(map).sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const makeDaysStartWorkingHourData: Parser = (events) => {
  const map: Record<string, DaySingleValue> = {};
  for (const evt of events) {
    if (evt.type !== 'work') {
      continue;
    }

    const startHour = getTimeInHour(evt.startTime);

    if (evt.startTime.getDay() === 0 || evt.startTime.getDay() === 6) {
      // exclude weekends
      continue;
    }

    const mmdd = toMMDD(evt.startTime);
    let info = map[mmdd];
    if (!info) {
      info = {
        date: getBeginOfDate(evt.startTime),
        label: JSON.stringify({
          top: `${mmdd}`,
          bottom: getShortenedDayText(evt.startTime),
        }),
        value: startHour,
      };
      map[mmdd] = info;
    } else {
      info.value = Math.min(info.value, startHour);
    }
  }
  return Object.values(map).sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const makeDaysEndWorkingHourData: Parser = (events) => {
  const map: Record<string, DaySingleValue> = {};
  for (const evt of events) {
    if (evt.type !== 'work') {
      continue;
    }

    let endHour = getTimeInHour(evt.endTime);
    if (evt.endTime.getDay() !== evt.startTime.getDay()) {
      // Adds 24 to the endHour if it belongs to the next day.
      endHour += 24;
    }

    if (evt.startTime.getDay() === 0 || evt.startTime.getDay() === 6) {
      // exclude weekends
      continue;
    }

    const mmdd = toMMDD(evt.startTime);
    let info = map[mmdd];
    if (!info) {
      info = {
        date: getBeginOfDate(evt.startTime),
        label: JSON.stringify({
          top: `${mmdd}`,
          bottom: getShortenedDayText(evt.startTime),
        }),
        value: endHour,
      };
      map[mmdd] = info;
    } else {
      info.value = Math.max(info.value, endHour);
    }
  }
  return Object.values(map).sort((a, b) => a.date.getTime() - b.date.getTime());
};

const createDaysTimeDurationDataParser: (type: CalendarName) => Parser = (
  type,
) => {
  const parser: Parser = (events) => {
    const map: Record<string, DaySingleValue> = {};
    for (const evt of events) {
      if (evt.type !== type) {
        continue;
      }

      const mmdd = toMMDD(evt.startTime);
      const durationInHour =
        (evt.endTime.getTime() - evt.startTime.getTime()) / 1000 / 60 / 60;
      let info = map[mmdd];
      if (!info) {
        info = {
          date: getBeginOfDate(evt.startTime),
          label: JSON.stringify({
            top: `${mmdd}`,
            bottom: getShortenedDayText(evt.startTime),
          }),
          value: durationInHour,
        };
        map[mmdd] = info;
      } else {
        info.value += durationInHour;
      }
    }
    return Object.values(map).sort(
      (a, b) => a.date.getTime() - b.date.getTime(),
    );
  };
  return parser;
};

export const makeDaysWorkTimeDurationData =
  createDaysTimeDurationDataParser('work');

export const makeDaysFamilyTimeDurationData =
  createDaysTimeDurationDataParser('family');
