import * as React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DaySingleValue } from '../../data_helpers';
import { DaysCustomizedAxisTick } from '../DaysCustomAxisTick/DaysCustomAxisTick';

interface Props {
  data: DaySingleValue[];
}

export const DaysLineChart: React.FC<Props> = ({ data }) => {
  return (
    <LineChart
      width={730}
      height={250}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis
        dataKey='label'
        height={60}
        interval={0}
        tick={<DaysCustomizedAxisTick />}
      />
      <YAxis />
      <Tooltip />
      <Line dataKey='value' stroke='#8884d8' />
    </LineChart>
  );
};
