import { Reducer, useMemo, useReducer } from 'react';

export interface FetchState<Data> {
  data?: Data;
  loading: boolean;
  error?: Error;
}

type Action<Data> =
  | { type: 'loadStarted' }
  | { type: 'loadSucceeded'; data: Data }
  | { type: 'loadFailed'; error: Error };

function createReducer<Data>() {
  const initState: FetchState<Data> = {
    loading: false,
  };

  const reducer: Reducer<FetchState<Data>, Action<Data>> = (state, action) => {
    switch (action.type) {
      case 'loadStarted':
        return { data: undefined, error: undefined, loading: true };
      case 'loadSucceeded':
        return { data: action.data, error: undefined, loading: false };
      case 'loadFailed':
        return { data: undefined, error: action.error, loading: false };
      default:
        return state;
    }
  };

  return [reducer, initState] as const;
}

export function useFetchReducer<Data>() {
  const [reducer, initState] = useMemo(() => createReducer<Data>(), []);
  return useReducer(reducer, initState);
}
